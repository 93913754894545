define('impact-admin/routes/frame/matching-codes/matching-code-group', ['exports', 'ember', 'impact-admin/utils/api', 'impact-admin/models/code-groups', 'impact-admin/models/organizations', 'impact-admin/config/environment', 'impact-admin/mixins/table'], function (exports, _ember, _impactAdminUtilsApi, _impactAdminModelsCodeGroups, _impactAdminModelsOrganizations, _impactAdminConfigEnvironment, _impactAdminMixinsTable) {
    exports['default'] = _ember['default'].Route.extend({
        model: function model(params) {
            var code_group_id = params.matching_code_group_id;
            return _ember['default'].RSVP.hash({
                organizations: new _ember['default'].RSVP.Promise(function (resolve) {
                    _impactAdminUtilsApi['default'].get('/match/organizations/' + code_group_id, {}, function (json) {
                        if (json.error) {
                            console.error('Failed to retrieve organizations. (' + json.error + ')');
                            resolve([]);
                        } else {
                            resolve(json.data);
                        }
                    }, true);
                }),
                donations: new _ember['default'].RSVP.Promise(function (resolve) {
                    _impactAdminUtilsApi['default'].get('/match/donations/' + code_group_id, {}, function (json) {
                        if (json.error) {
                            console.error('Failed to retrieve donations. (' + json.error + ')');
                            resolve([]);
                        } else {
                            resolve(json.data);
                        }
                    }, true);
                }),
                overage_donations: new _ember['default'].RSVP.Promise(function (resolve) {
                    _impactAdminUtilsApi['default'].get('/match/donations/overage/' + code_group_id, {}, function (json) {
                        if (json.error) {
                            console.error('Failed to retrieve donations. (' + json.error + ')');
                            resolve([]);
                        } else {
                            resolve(json.data);
                        }
                    }, true);
                }),
                codes: new _ember['default'].RSVP.Promise(function (resolve) {
                    _impactAdminUtilsApi['default'].get('/match/codes/' + code_group_id, {}, function (json) {
                        if (json.error) {
                            console.error('Failed to retrieve codes. (' + json.error + ')');
                            resolve([]);
                        } else {
                            resolve(json.data);
                        }
                    }, true);
                }),
                code_group: _impactAdminModelsCodeGroups['default'].find(code_group_id),
                balance: new _ember['default'].RSVP.Promise(function (resolve) {
                    _impactAdminUtilsApi['default'].get('/match/' + code_group_id + '/balance', {}, function (json) {
                        if (json.error) {
                            console.error('Failed to retrieve codes. (' + json.error + ')');
                            resolve([]);
                        } else {
                            resolve(json.data);
                        }
                    }, true);
                }),
                charities: _impactAdminModelsOrganizations['default'].find({ status: 'active' }),
                allCharities: _impactAdminModelsOrganizations['default'].find()
            });
        },
        setupController: function setupController(controller, model) {
            console.log(model);
            controller.set('model', model);
            controller.set('publicURL', _impactAdminConfigEnvironment['default'].publicAppURL);
            controller.set('donations_link', _impactAdminConfigEnvironment['default'].adminAppURL + '/#/export/' + model.code_group.export_id);

            var code_group = model.code_group;
            if (code_group.overage_org_id) {
                var organizations = model.allCharities;
                var org = organizations.find(function (org) {
                    return org.id === code_group.overage_org_id;
                });
                controller.set('selectedOrg', org || {});
            } else {
                controller.set('selectedOrg', {});
            }

            _impactAdminMixinsTable['default'].apply(controller);
        }
    });
});