define('impact-admin/routes/frame/payments', ['exports', 'ember', 'impact-admin/models/organizations', 'impact-admin/models/organization-profile-statuses', 'impact-admin/models/organization-permissions', 'impact-admin/utils/api'], function (exports, _ember, _impactAdminModelsOrganizations, _impactAdminModelsOrganizationProfileStatuses, _impactAdminModelsOrganizationPermissions, _impactAdminUtilsApi) {
    exports['default'] = _ember['default'].Route.extend({
        beforeModel: function beforeModel(transition) {
            //Check to see if this charity is requesting only its own things
            if (!this.controllerFor('application').get('isImpact')) {
                var charityIdDefined = transition.params['frame.payments'].charity_id;
                var charityId = this.controllerFor('application').get('user.organization_id');

                if (charityIdDefined.toString() !== charityId.toString()) {
                    transition.abort();
                }
            }
        },

        model: function model(params) {
            var organization = _impactAdminModelsOrganizations['default'].find(params.charity_id);
            var statuses = _impactAdminModelsOrganizationProfileStatuses['default'].findFirst({
                organization_id: params.charity_id
            });
            var permissions = _impactAdminModelsOrganizationPermissions['default'].findFirst({
                organization_id: params.charity_id
            });

            var hash = {
                organization: organization,
                statuses: statuses,
                permissions: permissions
            };

            return _ember['default'].RSVP.hash(hash);
        },

        setupController: function setupController(controller, model) {
            controller.set('model', model);
            controller.set('org_id', model.organization.id);
            controller.set('organization', model.organization);
            controller.set('permissions', model.permissions);
            controller.set('statuses', model.statuses);

            controller.setupPaymentOptions(model.organization);

            if (controller.get('organization.have_registered_for_stripe')) {
                var dmfOrImpact = _.some(model.organization.get('types'), function (type) {
                    return type.name === 'DMF Funds' || type.name === 'Impact Funds';
                });

                if (!dmfOrImpact || controller.get('application.isImpact')) {
                    controller.set('loading_stripe', true);
                    _impactAdminUtilsApi['default'].get('/organizations/' + controller.get('org_id') + '/stripe_info', {
                        id: controller.get('org_id')
                    }, function (json) {
                        if (json.error) {
                            controller.set('stripe_error', true);
                            controller.set('loading_stripe', false);
                        } else {
                            controller.set('can_see_stripe', true);
                            controller.set('stripe_balance', json.result.balance);
                            controller.set('stripe_account', json.result.account);
                            controller.set('loading_stripe', false);
                        }
                    }, true);
                }
            }

            controller.reset();
        }
    });
});
/* globals _ */