define('impact-admin/helpers/address-is-in-array', ['exports', 'ember'], function (exports, _ember) {

    var helper = _ember['default'].Helper.helper(function (params) {
        var objectToCheck = params[0];
        var arrayToCheck = params[1];
        if (!objectToCheck || !arrayToCheck) {
            return false;
        }

        var foundSelected = arrayToCheck.find(function (x) {
            if (x.ghd_year === 2025) {
                return JSON.stringify(x.address) === JSON.stringify(objectToCheck.address);
            }
        });
        if (foundSelected) {
            return true;
        }

        return false;
    });

    exports['default'] = helper;
});