define('impact-admin/controllers/frame/donors/donor', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        application: _ember['default'].inject.controller(),
        sortProperties: ['date'],
        sortAscending: false,
        alerts: [],
        edit: false,
        queryParams: ['page', 'pageSize'],
        page: 1,
        pageSize: 10,

        hasMorePages: _ember['default'].computed('page', function () {
            return this.get('model.donations.length') > this.get('page') * this.get('pageSize');
        }),

        hasLessPages: _ember['default'].computed('page', function () {
            return this.get('page') > 1;
        }),

        actions: {
            edit: function edit() {
                this.set('edit', true);
            },
            cancel: function cancel() {
                this.get('model.donor').revertRecord();
                this.set('edit', false);
            },
            updateAccount: function updateAccount() {
                var self = this;
                this.set('loading', true);
                this.set('alerts', []);
                if (!this.get('model.donor.first_name')) {
                    this.get('alerts').pushObject('First name is required.');
                }
                if (!this.get('model.donor.last_name')) {
                    this.get('alerts').pushObject('Last name is required.');
                }
                if (!this.get('model.donor.email')) {
                    this.get('alerts').pushObject('Email is required.');
                }

                if (this.get('alerts').length === 0) {
                    var userRecord = this.get('model.donor');
                    if (userRecord.isReallyDirty()) {
                        userRecord.save().then(function (result) {
                            if (!result.response.error) {
                                self.set('user', result.record);
                                self.growl.success('Account Updated');
                            } else {
                                self.get('alerts').pushObject('There was a problem updating your account information. Please try again.');
                            }
                            self.set('loading', false);
                            self.set('edit', false);
                        });
                    } else {
                        this.growl.success('Account Updated');
                        this.set('edit', false);
                        this.set('loading', false);
                    }
                } else {
                    this.set('loading', false);
                }
            },
            getNext: function getNext() {
                if (this.get('hasMorePages')) {
                    this.incrementProperty('page');
                    this.goToPage();
                }
            },
            getPrevious: function getPrevious() {
                if (this.get('hasLessPages')) {
                    this.decrementProperty('page');
                    this.goToPage();
                }
            },
            getFirst: function getFirst() {
                this.set('page', 1);
                this.goToPage();
            },
            getLast: function getLast() {
                var totalPages = Math.ceil(this.get('model.donations.length') / this.get('pageSize'));
                this.set('page', totalPages);
                this.goToPage();
            }
        },
        goToPage: function goToPage() {
            var page = this.get('page');
            var pageSize = this.get('pageSize');
            var donations = this.get('model.donations');
            this.set('paginatedDonations', donations.slice((page - 1) * pageSize, page * pageSize));
        },
        isFacebookUser: _ember['default'].computed('model.facebook_id', function () {
            return this.get('model.donor.facebook_id');
        }),

        isGoogleUser: _ember['default'].computed('model.google_id', function () {
            return this.get('model.donor.google_id');
        }),

        reset: function reset() {
            this.set('edit', false);
            this.set('viewingReceipt', false);
            this.set('receipt', null);
            this.set('alerts', []);
        }
    });
});