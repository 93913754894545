define('impact-admin/components/impact-footer', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        currentYear: new Date().getFullYear(),

        actions: {
            goToLink: function goToLink(link) {
                if (link) {
                    window.open(link, '_blank');
                }
            }
        }
    });
});