define('impact-admin/controllers/frame/prepaid-codes/index', ['exports', 'ember', 'impact-admin/utils/api', 'moment', 'impact-admin/utils/ghd-helper'], function (exports, _ember, _impactAdminUtilsApi, _moment, _impactAdminUtilsGhdHelper) {
    exports['default'] = _ember['default'].Controller.extend({
        nextGHDYear: _impactAdminUtilsGhdHelper['default'].calculateNextGivingHeartsYear((0, _moment['default'])()),

        /**
         * Current filter object
         */

        filters: {},

        setFilterFirstTime: (function () {
            this.set('filter', { endDate: "2025" });
        }).on('init'),

        descOrderSort: ['id:desc'],
        codeGroupsSorted: _ember['default'].computed.sort('codeGroups', 'descOrderSort'),

        codeBanks: _ember['default'].computed.filter('codeGroups', function (group) {
            return group.get('physical_cards') || group.get('physical_cards');
        }),
        codes: (function () {
            var _this = this;

            var codes = this.get('codeGroupsSorted'),
                filters = this.get('filters'),
                result = codes;

            _.each(filters, function (value, key) {
                var resolver = 'match' + key.capitalize();

                if (_.isFunction(_this[resolver])) {
                    result = result.filter(_this[resolver], {
                        filterBy: value
                    });
                } else {
                    console.warn('No function found', resolver);
                }
            });

            return result;
        }).property('filters', 'codeGroupsSorted'),

        /**
         * Compares the startDate to a list item
         * @param item The individual item in the list to filter
         * @returns {boolean}
         */
        matchEndDate: function matchEndDate(item) {
            if (this.filterBy === 'all') {
                return true;
            }

            var sanitizedFilterBy = Number(this.filterBy);

            return item && item.end_date && new Date(item.end_date).getFullYear() === sanitizedFilterBy;
        },

        matchFulfilled: function matchFulfilled(item) {
            if (this.filterBy === 'all') {
                return true;
            }

            if (this.filterBy === 'yes') {
                return item && item.date_fulfilled && item.fulfilled_by && item.verified_by;
            }

            if (this.filterBy === 'no') {
                return item && (!item.date_fulfilled || !item.fulfilled_by || !item.verified_by);
            }
        },

        matchPurchaseType: function matchPurchaseType(item) {
            if (this.filterBy === 'all') {
                return true;
            }

            if (this.filterBy === 'online') {
                return item && item.online_code;
            }

            if (this.filterBy === 'manual') {
                return item && !item.online_code && item.physical_card_group_id;
            }
        },

        actions: {
            newCode: function newCode() {
                return this.transitionToRoute('frame.prepaid-codes.new');
            },
            transitionToReport: function transitionToReport(id) {
                return this.transitionToRoute('frame.prepaid-codes.prepaid-code-group', id);
            },
            updateFilters: function updateFilters(filters) {
                this.set('filters', filters);
            },
            applyRemainder: function applyRemainder() {
                var _this2 = this;

                console.log('applyRemainder', this.get('codeGroups'));
                var codeGroupsWithOverageOrgId = this.get('codeGroups').filter(function (item) {
                    return item && item.end_date && new Date(item.end_date).getFullYear().toString() === new Date().getFullYear().toString() && item.overage_org_id && !item.remainder_applied;
                });

                if (!codeGroupsWithOverageOrgId.length) {
                    this.growl.error('No groups to apply remainder to.');
                    return;
                }

                if (!window.confirm('Apply remaining donations from ' + codeGroupsWithOverageOrgId.length + ' prepaid code groups? This will NOT payout group.')) {
                    return;
                }

                var applyRemainders = codeGroupsWithOverageOrgId.map(function (group) {
                    var overageOrgId = group.overage_org_id;
                    var prepaidGroupId = group.id;

                    return _impactAdminUtilsApi['default'].post('/prepaid_code_groups/' + prepaidGroupId + '/apply_remainder', { overageOrgId: overageOrgId }, function (res) {
                        _this2.set('loading', false);

                        if (res.error) {
                            _this2.growl.error(res.error);
                            return;
                        }
                    }, true);
                });

                _ember['default'].RSVP.Promise.all(applyRemainders).then(function (result) {
                    var successes = result.filter(function (r) {
                        return r.donationsSucceeded;
                    });
                    _this2.growl.success(successes.length + ' groups donations were applied');
                });
            },
            filterEndDate: function filterEndDate(newYear) {
                var _this3 = this;

                var year = newYear === 'all' ? 0 : newYear;

                // Call the API with the new year and update codeGroups
                _impactAdminUtilsApi['default'].get('/prepaid_code_groups_year', { year: year }, function (response) {
                    _this3.set('codeGroups', _ember['default'].A(response.data));
                }, true);
            }
        }
    });
});
/* global _ */