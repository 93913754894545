define('impact-admin/routes/frame/service-map-list', ['exports', 'ember', 'impact-admin/models/events', 'impact-admin/models/event-icons'], function (exports, _ember, _impactAdminModelsEvents, _impactAdminModelsEventIcons) {
    exports['default'] = _ember['default'].Route.extend({
        model: function model() {
            return _impactAdminModelsEvents['default'].find();
        },

        setupController: function setupController(controller, model) {
            var _this = this;

            this._super(controller, model);
            _impactAdminModelsEventIcons['default'].find().then(function (icons) {
                icons = icons.toArray();
                controller.set('eventIcons', icons);

                var formattedEvents = model.toArray();
                formattedEvents.map(function (event) {
                    var jsonStringHours = '' + event.hours;
                    _ember['default'].set(event, 'hours', JSON.parse(jsonStringHours));
                    var jsonStringAddress = '' + event.address;
                    _ember['default'].set(event, 'address', JSON.parse(jsonStringAddress));
                });
                controller.set('events', formattedEvents);
                controller.set('originalEvents', JSON.parse(JSON.stringify(formattedEvents)));
                if (!_this.controllerFor('application').get('isImpact')) {
                    _this.transitionTo('frame.dashboard');
                }
            });
        }
    });
});