define('impact-admin/controllers/frame/matching-codes/new', ['exports', 'ember', 'impact-admin/models/code-groups', 'moment'], function (exports, _ember, _impactAdminModelsCodeGroups, _moment) {
    exports['default'] = _ember['default'].Controller.extend({

        code_groups: [],
        selected_code_group: null,
        search_text: '',

        validCharities: _ember['default'].computed('model', function () {
            var validOrgs = this.get('selected_code_group.validOrganizations');
            var charities = this.get('charities');

            var filteredCharities = [];
            validOrgs.forEach(function (_ref) {
                var organization_id = _ref.organization_id;
                return filteredCharities.push(charities.find(function (it) {
                    return it.id === organization_id;
                }));
            });
            return filteredCharities;
        }),

        noMatchDollars: _ember['default'].computed('match_type', function () {
            return this.get('match_type').name !== 'Dollar for dollar';
        }),

        searchObserve: (function () {
            var search_text = this.get('search_text');
            if (search_text.length > 2) {
                var code_groups = _impactAdminModelsCodeGroups['default'].find({
                    name: {
                        'ilike': '%' + this.get('search_text') + '%'
                    }
                });
                this.set('code_groups', code_groups);
            } else {
                this.set('code_groups', []);
            }
        }).observes('search_text'),

        require_sharing_consent: true,
        single_code: true,
        match_types: [{ value: 'none', name: 'None' }, { value: 'dollarForDollar', name: 'Dollar for dollar' }],
        init: function init() {},

        jqueryInit: (function () {

            _ember['default'].run.schedule("afterRender", this, function () {

                //jQuery for disabling the scrolling mechanic for number inputs, made the forms user error prone
                $('form').on('focus', 'input[type=number]', function () {
                    $(this).on('wheel.disableScroll', function (e) {
                        e.preventDefault();
                    });
                });
                $('form').on('blur', 'input[type=number]', function () {
                    $(this).off('wheel.disableScroll');
                });
            });
        }).on('init'),

        actions: {

            importSelectedValueToNew: function importSelectedValueToNew(value, variable, successMessage) {
                this.set(variable, value);
                this.growl.success(successMessage);
            },

            //We store our values in cents, so make sure we are displaying the correct dollar amounts to end users.
            importMatchCodeRestrictions: function importMatchCodeRestrictions(codeGroup) {
                if (codeGroup.min_cart_total) {
                    this.set('min_cart_total', parseInt(codeGroup.min_cart_total) / 100);
                }
                if (codeGroup.min_donation_size) {
                    this.set('min_donation_size', parseInt(codeGroup.min_donation_size) / 100);
                }
                if (codeGroup.min_donations_per_code) {
                    this.set('min_donations_per_code', codeGroup.min_donations_per_code);
                }
                if (codeGroup.max_cart_total) {
                    this.set('max_cart_total', parseInt(codeGroup.max_cart_total) / 100);
                }
                if (codeGroup.max_donation_size) {
                    this.set('max_donation_size', parseInt(codeGroup.max_donation_size) / 100);
                }
                if (codeGroup.max_donations_per_code) {
                    this.set('max_donations_per_code', codeGroup.max_donations_per_code);
                }
                if (codeGroup.max_match_per_code) {
                    this.set('max_match_per_code', parseInt(codeGroup.max_match_per_code) / 100);
                }
                this.growl.success('Restrictions / Limitations Copied!');
            },

            importSelectedCharityTypes: function importSelectedCharityTypes(selected) {
                var all_charity_types = this.get('charity_types');
                this.set('allowed_charity_types', []);

                //note the local scope, we will set this to the controller's scope at the end
                var allowed_charity_types = [];

                selected.forEach(function (selectedType) {
                    var found_type = all_charity_types.find(function (type) {
                        return type.id === selectedType.type_id;
                    });
                    if (found_type) {
                        allowed_charity_types.push(found_type);
                    }
                });

                //Finally set the allowed charities from the old group to the current model of the new group
                this.set('allowed_charity_types', allowed_charity_types);
                this.growl.success('Allowed Charity Types Copied!');
            },

            importSelectedCharities: function importSelectedCharities(selected) {
                var all_charities = this.get('charities');
                this.set('allowed_charities', []);
                var allowed_charities = [];

                selected.forEach(function (selectedOrg) {
                    var found_charity = all_charities.find(function (charity) {
                        return charity.id === selectedOrg.organization_id;
                    });
                    if (found_charity) {
                        allowed_charities.push(found_charity);
                    }
                });

                //Finally set the allowed charities from the old group to the current model of the new group
                this.set('allowed_charities', allowed_charities);
                this.growl.success('Allowed Charities Copied!');
            },

            setCodeGroupToImport: function setCodeGroupToImport(selectedGroup) {
                console.log(selectedGroup);
                this.set('selected_code_group', selectedGroup);
                this.set('code_groups', []);
                this.set('search_text', '');
            },

            save: function save() {
                var _this = this;

                var existing_codes = this.get('codes').toArray();
                var temp_code_name = this.get('code_name');
                if (existing_codes.find(function (code) {
                    return code.code === temp_code_name;
                })) {
                    this.growl.error('Unique code already exists. Please choose a unique code name.');
                    return;
                }
                // Collect input values

                var _getProperties = this.getProperties('group_name', 'group_description', 'start_date', 'end_date', 'single_code', 'multiple_code_email', 'emails', 'code_name', 'min_donations_per_code', 'max_donations_per_code', 'min_donation_size', 'max_donation_size', 'min_cart_total', 'max_cart_total', 'max_uses', 'match_type', 'allowed_charity_types', 'allowed_charities', 'max_match_per_code', 'require_sharing_consent', 'report_access_code', 'logo');

                var group_name = _getProperties.group_name;
                var group_description = _getProperties.group_description;
                var start_date = _getProperties.start_date;
                var end_date = _getProperties.end_date;
                var single_code = _getProperties.single_code;
                var multiple_code_email = _getProperties.multiple_code_email;
                var emails = _getProperties.emails;
                var code_name = _getProperties.code_name;
                var min_donations_per_code = _getProperties.min_donations_per_code;
                var max_donations_per_code = _getProperties.max_donations_per_code;
                var min_donation_size = _getProperties.min_donation_size;
                var max_donation_size = _getProperties.max_donation_size;
                var min_cart_total = _getProperties.min_cart_total;
                var max_cart_total = _getProperties.max_cart_total;
                var max_uses = _getProperties.max_uses;
                var match_type = _getProperties.match_type;
                var allowed_charity_types = _getProperties.allowed_charity_types;
                var allowed_charities = _getProperties.allowed_charities;
                var max_match_per_code = _getProperties.max_match_per_code;
                var require_sharing_consent = _getProperties.require_sharing_consent;
                var report_access_code = _getProperties.report_access_code;
                var logo = _getProperties.logo;

                var code_group = _impactAdminModelsCodeGroups['default'].createRecord({
                    // Group
                    name: group_name,
                    description: group_description,
                    start_date: this.formatDate(start_date),
                    end_date: this.formatDate(end_date),
                    logo: logo,
                    // Code Generation
                    code_name: single_code ? code_name : null,
                    num_codes: this.getNumberOfCodes(),
                    email_codes: multiple_code_email ? emails : null,

                    // Restrictions
                    min_donations_per_code: min_donations_per_code,
                    max_donations_per_code: max_donations_per_code,
                    // Input is in dollars, database is in cents
                    min_donation_size: this.convertToCents(min_donation_size),
                    max_donation_size: this.convertToCents(max_donation_size),
                    min_cart_total: this.convertToCents(min_cart_total),
                    max_cart_total: this.convertToCents(max_cart_total),
                    max_uses: max_uses,
                    allowed_charity_types: allowed_charity_types && allowed_charity_types.length > 0 ? allowed_charity_types.map(function (type) {
                        return type.id;
                    }) : [],
                    allowed_charities: allowed_charities && allowed_charities.length > 0 ? allowed_charities.map(function (charity) {
                        return charity.id;
                    }) : [],

                    // Matching
                    match_type: match_type.value,
                    max_match_per_code: this.convertToCents(max_match_per_code),
                    require_sharing_consent: require_sharing_consent,
                    access_code: report_access_code
                });

                if (this.validate()) {
                    code_group.save().then(function (json) {
                        var code_group_id = json.record.get('id');
                        console.log('New matching code has been saved with id ' + code_group_id + '.');
                        _this.growl.success('Matching code saved');
                        _this.transitionToRoute('frame.matching-codes.matching-code-group', code_group_id);
                    })['catch'](function (error) {
                        console.error(error);
                        _this.growl.error('An error occurred saving this matching code. Please try again');
                    });
                }
            },
            setVisibility: function setVisibility(visible) {
                this.setProperties({
                    single_code: visible === 'single_code',
                    multiple_code: visible === 'multiple_code',
                    multiple_code_email: visible === 'multiple_code_email'
                });
            },
            updateMaxUseInput: function updateMaxUseInput(single_use_code) {
                var max_uses = single_use_code ? "1" : null;
                this.setProperties({
                    max_uses: max_uses
                });
            },
            updateSingleUseCheckbox: function updateSingleUseCheckbox(max_uses) {
                var single_use_code = max_uses === "1";
                this.setProperties({
                    single_use_code: single_use_code
                });
            }
        },
        convertToCents: function convertToCents(dollars) {
            var value = this.numberOrNull(dollars);
            return value == null ? null : value * 100;
        },
        formatDate: function formatDate(date) {
            return date ? date.toJSON() : null;
        },
        getNumberOfCodes: function getNumberOfCodes() {
            if (this.get('multiple_code')) {
                return this.numberOrNull(this.get('num_codes'));
            } else {
                return this.get('single_code') ? 1 : null;
            }
        },
        numberOrNull: function numberOrNull(numString) {
            var number = parseInt(numString, 10);
            return isNaN(number) ? null : number;
        },
        validate: function validate() {
            var isNullOrUndefinedOrEmpty = function isNullOrUndefinedOrEmpty(obj) {
                return obj === undefined || obj === null || obj === '';
            };

            var _getProperties2 = this.getProperties('group_name', 'emails');

            var group_name = _getProperties2.group_name;
            var emails = _getProperties2.emails;

            this.set('errors', _ember['default'].A());
            var errors = this.get('errors');

            if (isNullOrUndefinedOrEmpty(group_name)) {
                errors.pushObject('Please enter a code group name.');
            }

            var num_codes = this.getNumberOfCodes();
            if ((isNullOrUndefinedOrEmpty(num_codes) || num_codes < 1) && isNullOrUndefinedOrEmpty(emails)) {
                errors.pushObject('Please enter the number of codes to generate or an email list.');
            }

            return errors.length === 0;
        },
        reset: function reset() {
            this.setProperties({
                // Group
                group_name: '',
                group_description: '',
                start_date: (0, _moment['default'])(),
                end_date: (0, _moment['default'])("2025-2-14"),
                logo: '',

                // Code Generation
                single_code: true,
                num_codes: '',
                multiple_code: false,
                multiple_code_email: false,
                emails: '',
                code_name: '',

                // Restrictions
                min_donations_per_code: '',
                max_donations_per_code: '',
                min_donation_size: '',
                max_donation_size: '',
                min_cart_total: '',
                max_cart_total: '',
                max_uses: '',
                allowed_charity_types: [],
                allowed_charities: [],

                // Matching
                match_type: this.get('match_types').filter(function (type) {
                    return type.value === 'dollarForDollar';
                })[0],
                max_match_per_code: '',
                single_use_code: false,
                require_sharing_consent: true,
                report_access_code: ''
            });
        }
    });
});