define('impact-admin/controllers/frame/service-map-list', ['exports', 'ember', 'impact-admin/models/events'], function (exports, _ember, _impactAdminModelsEvents) {
    exports['default'] = _ember['default'].Controller.extend({
        events: [], // Initialize as an empty array or with your data
        currentYearShown: 2024,
        actions: {
            expandEvent: function expandEvent(event) {
                var tempEvents = JSON.parse(JSON.stringify(this.get('events')));
                tempEvents.find(function (e) {
                    if (e.id === event.id) {
                        e.isExpanded = !e.isExpanded;
                    }
                });
                this.set('events', tempEvents);
            },
            updateCurrentYearShown: function updateCurrentYearShown(e) {
                var year = parseInt(e.target.value);
                this.set('currentYearShown', year);
            },
            openLink: function openLink(link) {
                var win = window.open(link, '_blank');
                win.focus();
            },

            moveToNewYear: function moveToNewYear(event) {
                var tempEvents = JSON.parse(JSON.stringify(this.get('events')));
                var originalEvent = JSON.parse(JSON.stringify(this.get('originalEvents').find(function (e) {
                    return e.id === event.id;
                })));
                tempEvents.find(function (e) {
                    if (e.id === event.id) {
                        e.ghd_year = 2025;
                        if (originalEvent.ghd_year === 2025) {
                            e.hasChanged = false;
                        } else {
                            e.hasChanged = true;
                        }
                    }
                });
                this.set('events', tempEvents);
                console.log(tempEvents);
            },
            moveToOldYear: function moveToOldYear(event) {
                var tempEvents = JSON.parse(JSON.stringify(this.get('events')));
                var originalEvent = JSON.parse(JSON.stringify(this.get('originalEvents').find(function (e) {
                    return e.id === event.id;
                })));
                tempEvents.find(function (e) {
                    if (e.id === event.id) {
                        e.ghd_year = 2024;
                        if (originalEvent.ghd_year === 2024) {
                            e.hasChanged = false;
                        } else {
                            e.hasChanged = true;
                        }
                    }
                });
                this.set('events', tempEvents);
            },
            editEvents: function editEvents() {
                var _this = this;

                var events = this.get('events'); // Get the events array
                var savePromises = events.filter(function (e) {
                    return e.hasChanged === true;
                }) // Filter events with changes
                .map(function (e) {
                    // Fetch the existing record from Ember Data
                    // Update the record's properties
                    var tempEvent = {
                        type: e.type,
                        title: e.title,
                        description: e.description,
                        address: JSON.stringify(e.address), // Ensure address is a string
                        hours: JSON.stringify(e.hours), // Ensure hours is a string
                        latitude: parseFloat(e.latitude), // Ensure latitude is a float
                        longitude: parseFloat(e.longitude), // Ensure longitude is a float
                        google_maps: e.google_maps,
                        apple_maps: e.apple_maps,
                        icon: parseInt(e.icon, 10), // Ensure icon is an integer
                        ghd_year: parseInt(e.ghd_year, 10) // Ensure ghd_year is an integer
                    };
                    var newEvent = _impactAdminModelsEvents['default'].createRecord(tempEvent);
                    newEvent.save();
                });
                Promise.all(savePromises).then(function () {
                    _this.growl.success('All events successfully edited!');
                    var tEvents = JSON.parse(JSON.stringify(_this.get('events')));
                    tEvents.map(function (event) {
                        event.hasChanged = false;
                    });
                    _this.set('events', JSON.parse(JSON.stringify(tEvents)));
                    _this.set('originalEvents', tEvents);
                })['catch'](function (error) {
                    console.error('Error saving events:', error);
                    _this.growl.error('An error occurred updating the events. Please try again.');
                });
            }
        },
        countChangedEvents: _ember['default'].computed('events.@each.hasChanged', function () {
            return this.get('events').filter(function (event) {
                return event.hasChanged;
            }).length;
        })
    });
});