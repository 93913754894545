define('impact-admin/routes/frame/donors/donor', ['exports', 'ember', 'impact-admin/models/users', 'impact-admin/models/donations'], function (exports, _ember, _impactAdminModelsUsers, _impactAdminModelsDonations) {
    exports['default'] = _ember['default'].Route.extend({
        queryParams: {
            page: {
                refreshModel: false
            },
            pageSize: {
                refreshModel: false
            }
        },

        model: function model(params) {
            // Fetch the donor record
            var donor = _impactAdminModelsUsers['default'].find(params.donor_id);

            // Fetch the donations for the donor
            var donations = _impactAdminModelsDonations['default'].find({ user_id: params.donor_id });

            // Return a hash with both the donor and donations
            return _ember['default'].RSVP.hash({
                donor: donor,
                donations: donations
            });
        },

        setupController: function setupController(controller, model) {
            this._super(controller, model);

            // Extract donations from the model
            var donations = model.donations || [];
            var page = parseInt(controller.page || 1, 10);
            var pageSize = parseInt(controller.pageSize || 10, 10);

            // Paginate donations
            var paginatedDonations = donations.slice((page - 1) * pageSize, page * pageSize);

            // Set paginated donations and metadata on the controller
            controller.setProperties({
                paginatedDonations: paginatedDonations,
                totalDonations: donations.length,
                page: page,
                pageSize: pageSize
            });
        }
    });
});