define('impact-admin/models/donations', ['exports', 'ember', 'impact-admin/models/transactions', 'impact-admin/models/organizations', 'impact-admin/models/users', 'impact-admin/models/code-groups'], function (exports, _ember, _impactAdminModelsTransactions, _impactAdminModelsOrganizations, _impactAdminModelsUsers, _impactAdminModelsCodeGroups) {

    /*
     *Donations Model
     */
    var Donations = _ember['default'].Model.extend({
        shortenName: (function () {
            var cutOff = 14;
            var first = this.get('first_name');
            var last = this.get('last_name');

            if (first && last) {
                var len = last.length + first.length;
                if (len > cutOff) {
                    var full_name = last + ', ' + first;
                    return full_name.substring(0, cutOff - 3) + '...';
                }
                return first + ', ' + last;
            } else if (last) {
                return last;
            } else if (first) {
                return first;
            } else {
                return 'GUEST';
            }
        }).property('first_name', 'last_name'),

        isCheck: (function () {
            if (this.get('transaction_type') === 'Check') {
                return true;
            }
            return false;
        }).property('transaction_type'),

        isPrize: (function () {
            if (this.get('transaction_type') === 'Incentives/Doorprizes') {
                return true;
            }
            return false;
        }).property('transaction_type'),

        isMatchOverage: (function () {
            return this.get('code_group_match_amount') && !this.get('amount');
        }).property('code_group_match_amount', 'amount'),

        isCreditCard: (function () {
            if (this.get('transaction_type') === 'stripe_invoice' || this.get('transaction_type') === 'stripe') {
                return true;
            }
            return false;
        }).property('transaction_type'),

        isScheduled: (function () {
            if (this.get('scheduled_id')) {
                return true;
            }
            return false;
        }).property('scheduled_id'),

        donor: _ember['default'].computed('user_id', function () {
            return _impactAdminModelsUsers['default'].find(this.get('user_id'));
        }),

        transaction: _ember['default'].computed('transaction_id', function () {
            return _impactAdminModelsTransactions['default'].find(this.get('transaction_id'));
        }),

        organization: _ember['default'].computed('organization_id', function () {
            return _impactAdminModelsOrganizations['default'].find(this.get('organization_id'));
        }),

        codeGroup: _ember['default'].computed('transaction.matching_code_group_id', function () {
            return _impactAdminModelsCodeGroups['default'].find(this.get('transaction.matching_code_group_id'));
        })

    });
    Donations.table = 'donations';

    exports['default'] = Donations;
});