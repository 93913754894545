define('impact-admin/controllers/frame/service-map', ['exports', '@googlemaps/js-api-loader', 'ember', 'impact-admin/models/events'], function (exports, _googlemapsJsApiLoader, _ember, _impactAdminModelsEvents) {

    var loader = new _googlemapsJsApiLoader.Loader({
        apiKey: '',
        version: 'weekly',
        libraries: ['places']
    });

    var mapOptions = {
        center: {
            lat: 47.534875,
            lng: -101.1248
        },
        zoom: 6.5,
        streetViewControl: false //disable street-view
    };

    var addMarker = function addMarker(event, self) {
        var map = self.map;
        var feature = {
            position: new google.maps.LatLng(event.latitude, event.longitude)
        };

        var myIcon = self.eventIcons.find(function (icon) {
            return icon.id === event.icon;
        });
        myIcon = myIcon.icon;

        var marker = new google.maps.Marker({
            position: feature.position,
            icon: myIcon,
            map: map,
            title: event.title,
            animation: google.maps.Animation.DROP,
            id: event.id
        });
        marker.setMap(map);
        marker.addListener('click', function () {
            var temp = JSON.parse(JSON.stringify(self.events));
            temp.forEach(function (te) {
                _ember['default'].set(te, 'isSelected', false);
                if (te.id === event.id) {
                    _ember['default'].set(te, 'isSelected', true);
                }
            });
            self.markers.forEach(function (marker) {
                return marker.setAnimation(null);
            });
            marker.animation = google.maps.Animation.BOUNCE;
            self.set('events', {});
            self.set('events', temp);
            map.setCenter(new google.maps.LatLng(event.latitude, event.longitude));
            map.setZoom(15);
            self.actions.scrollIntoElement(event);
        });
        self.markers.push(marker);
    };
    exports['default'] = _ember['default'].Controller.extend({
        map: null,
        markers: [],
        isManagingIcons: false,
        dayOfTheWeek: '',

        //barrel being edited or added
        newEvent: null,
        editEvent: null,
        eventBeingEdited: null,
        //latitude/longitude of the barrel that was just added/edited/deleted, so we can refresh the map and zoom into that spot
        focusedPos: { 'latitude': null, 'longitude': null },

        actions: {
            openLink: function openLink(link) {
                var win = window.open(link, '_blank');
                win.focus();
            },
            setNewEvent: function setNewEvent(value) {
                this.set('editEvent', null);
                if (value === true) {
                    var temp = {
                        'address': {},
                        'hours': {},
                        'icon': 3
                    };
                    this.set('newEvent', temp);
                } else {
                    this.set('newEvent', null);
                }
            },
            setEditEvent: function setEditEvent(event) {
                this.set('newEvent', null);
                this.set('editEvent', event);
            },
            setSelectedEvent: function setSelectedEvent(event) {
                this.markers.forEach(function (marker) {
                    marker.setAnimation(null);
                    if (marker.title === event.title) {
                        marker.setAnimation(google.maps.Animation.BOUNCE);
                    }
                });
                this.map.setCenter(new google.maps.LatLng(event.latitude, event.longitude));
                this.map.setZoom(14);
                this.events.forEach(function (event) {
                    return _ember['default'].set(event, 'isSelected', false);
                });
                this.actions.scrollIntoElement(event);
                _ember['default'].set(event, 'isSelected', true);
            },
            setSelectedIcon: function setSelectedIcon(event, icon) {
                _ember['default'].set(event, 'icon', icon);
            },
            setManagingIcons: function setManagingIcons(value) {
                this.set('isManagingIcons', value);
            },
            initMap: function initMap(self, events) {
                loader.importLibrary('maps').then(function (_ref) {
                    var Map = _ref.Map;

                    var map = null;
                    map = new Map(document.getElementById('map'), mapOptions);
                    self.set('map', map);

                    // Initialize Autocomplete
                    var input = document.getElementById('pac-input');
                    var autocomplete = new google.maps.places.Autocomplete(input);
                    autocomplete.bindTo('bounds', map);

                    // Add event listener for place search
                    autocomplete.addListener('place_changed', function () {
                        var place = autocomplete.getPlace();

                        if (!place.geometry) {
                            // User entered the name of a Place that was not suggested and pressed the Enter key
                            return;
                        }
                        var coords = {
                            'latitude': place.geometry.location.lat(),
                            'longitude': place.geometry.location.lng()
                        };
                        var temp = self.actions.sortNearestEvents(coords, self.get('events'));
                        self.set('events', []);
                        self.set('events', temp);

                        // Update map location to the selected place
                        map.setCenter(place.geometry.location);
                        map.setZoom(18); // Set an appropriate zoom level
                    });

                    map.addListener("click", function (e) {
                        //Get the latitude and longitude of the clicked point
                        var clickedLat = e.latLng.lat().toFixed(4);
                        var clickedLng = e.latLng.lng().toFixed(4);
                        if (self.editEvent) {
                            if (confirm('Are you sure you want to set the latitude and longitude for ' + self.editEvent.title + '?')) {
                                _ember['default'].set(self.editEvent, 'latitude', clickedLat);
                                _ember['default'].set(self.editEvent, 'longitude', clickedLng);
                            }
                        } else if (self.newEvent) {
                            _ember['default'].set(self.newEvent, 'latitude', clickedLat);
                            _ember['default'].set(self.newEvent, 'longitude', clickedLng);
                            console.log(self.newEvent);
                        }

                        // Use Places API to fetch place details
                        var placesService = new google.maps.places.PlacesService(map);
                        if (e.placeId) {
                            placesService.getDetails({
                                placeId: e.placeId
                            }, function (placeResult, status) {
                                if (status === google.maps.places.PlacesServiceStatus.OK) {
                                    var _ret = (function () {
                                        if (!self.newEvent) {
                                            return {
                                                v: undefined
                                            };
                                        }
                                        _ember['default'].set(self.newEvent, 'title', placeResult.name);
                                        // Create a temporary element to parse the HTML content
                                        var tempElement = document.createElement('div');
                                        tempElement.innerHTML = placeResult.adr_address;

                                        // Extract value by class name
                                        var extractValueByClassName = function extractValueByClassName(className) {
                                            var element = tempElement.querySelector('.' + className);
                                            if (element) {
                                                return element.textContent || element.innerText;
                                            }
                                            return null; // Class not found
                                        };

                                        var address = {
                                            'street': extractValueByClassName("street-address"),
                                            'city': extractValueByClassName("locality"),
                                            'state': extractValueByClassName("region"),
                                            'zip_code': extractValueByClassName("postal-code")
                                        };
                                        _ember['default'].set(self.newEvent, "address", address);
                                        _ember['default'].set(self.newEvent, "google_maps", placeResult.url);
                                        if (placeResult.opening_hours && placeResult.opening_hours.weekday_text) {
                                            var weekday_text = placeResult.opening_hours.weekday_text;
                                            var hours = {
                                                'monday': weekday_text[0].split(': ')[1],
                                                'tuesday': weekday_text[1].split(': ')[1],
                                                'wednesday': weekday_text[2].split(': ')[1],
                                                'thursday': weekday_text[3].split(': ')[1],
                                                'friday': weekday_text[4].split(': ')[1],
                                                'saturday': weekday_text[5].split(': ')[1],
                                                'sunday': weekday_text[6].split(': ')[1]
                                            };
                                            _ember['default'].set(self.newEvent, 'hours', hours);
                                        }
                                    })();

                                    if (typeof _ret === 'object') return _ret.v;
                                } else {
                                    console.error('Failed to fetch place details:', status);
                                }
                            });
                        }
                    });
                    //create markers
                    var markers = [];
                    self.set('markers', markers);
                    events.forEach(function (event) {
                        addMarker(event, self);
                    });
                });
            },
            copyIntoEvent: function copyIntoEvent(event, eventToCopy) {
                if (eventToCopy.id) {
                    _ember['default'].set(event, 'id', eventToCopy.id);
                }
                _ember['default'].set(event, 'title', eventToCopy.title);
                _ember['default'].set(event, 'latitude', eventToCopy.latitude);
                _ember['default'].set(event, 'longitude', eventToCopy.longitude);
                _ember['default'].set(event, 'address', JSON.stringify(eventToCopy.address));
                _ember['default'].set(event, 'hours', JSON.stringify(eventToCopy.hours));
                _ember['default'].set(event, 'google_maps', eventToCopy.google_maps);
                _ember['default'].set(event, 'apple_maps', eventToCopy.apple_maps);
            },
            emptyEvent: function emptyEvent(event) {
                _ember['default'].set(event, 'title', '');
                _ember['default'].set(event, 'longitude', '');
                _ember['default'].set(event, 'latitude', '');
                _ember['default'].set(event, 'address', {});
                _ember['default'].set(event, 'hours', {});
                _ember['default'].set(event, 'google_maps', '');
                _ember['default'].set(event, 'apple_maps', '');
            },
            setEditingEvent: function setEditingEvent(value) {
                //adding new event rather than editing an existing one
                if (value === false) {
                    this.set('newEvent', {});
                } else {
                    this.actions.copyIntoEvent(this.newEvent, this.eventToDisplay);
                    this.newEvent.hours = JSON.parse(this.newEvent.hours);
                    this.newEvent.address = JSON.parse(this.newEvent.address);
                }
            },
            addEvent: function addEvent() {
                var _this = this;

                var tempEvent = this.get('newEvent');
                if (!tempEvent.latitude || !tempEvent.longitude || !tempEvent.title) {
                    this.growl.error('Fill out the required fields then try again');
                    return;
                }
                //TODO change this to something else
                var newEvent = _impactAdminModelsEvents['default'].createRecord(tempEvent);
                newEvent.content.address = JSON.stringify(newEvent.content.address);
                newEvent.content.hours = JSON.stringify(newEvent.content.hours);
                newEvent.content.ghd_year = 2025;
                newEvent.save().then(function (res) {
                    var event = res.response.result[0];
                    event.address = JSON.parse(event.address);
                    event.hours = JSON.parse(event.hours);
                    event = _impactAdminModelsEvents['default'].createRecord(event).content;
                    var tempEvents = JSON.parse(JSON.stringify(_this.get('events')));
                    tempEvents.push(event);
                    _this.set('events', []);
                    _this.set('events', tempEvents);
                    _this.set('focusedPos', { 'latitude': event.latitude, 'longitude': event.longitude });
                    addMarker(event, _this);
                    _this.set('newEvent', null);
                    _this.growl.success('Event Added!');
                })['catch'](function (reason) {
                    console.log(reason);
                    _this.growl.error('An error occurred adding the event. Please try again.');
                });
            },
            editEvent: function editEvent() {
                var _this2 = this;

                var editEvent = this.get('editEvent');
                var event = this.events.find(function (event) {
                    return event.id === editEvent.id;
                });
                this.actions.copyIntoEvent(event, editEvent);
                event = _impactAdminModelsEvents['default'].createRecord(event);
                event.save().then(function (res) {
                    var resEvent = res.response.result[0];
                    resEvent.hours = JSON.parse(resEvent.hours);
                    resEvent.address = JSON.parse(resEvent.address);
                    var tempEvents = JSON.parse(JSON.stringify(_this2.get('events')));
                    tempEvents = tempEvents.map(function (tb) {
                        return tb.id === resEvent.id ? resEvent : tb;
                    });
                    _this2.set('events', []);
                    _this2.set('events', tempEvents);
                    _this2.set('focusedPos', { 'latitude': resEvent.latitude, 'longitude': resEvent.longitude });
                    _this2.set('markers', _this2.markers.filter(function (marker) {
                        if (marker.id === resEvent.id) {
                            marker.setMap(null);
                            return false;
                        }
                        return true;
                    }));
                    addMarker(resEvent, _this2);
                    _this2.set('editEvent', null);
                    _this2.growl.success('Event edited!');
                })['catch'](function (reason) {
                    console.log(reason);
                    _this2.growl.error('An error occurred updating the event. Please try again.');
                });
            },

            deleteEvent: function deleteEvent(selectedEvent) {
                var _this3 = this;

                var event = this.events.find(function (event) {
                    return event.id === selectedEvent.id;
                });
                if (!event._super) {
                    event = _impactAdminModelsEvents['default'].createRecord(event).content;
                }
                if (confirm('Are you sure you want to remove the ' + event.title + ' event?')) {
                    event.deleteRecord().then(function () {
                        var tempEvents = JSON.parse(JSON.stringify(_this3.events));
                        tempEvents = tempEvents.filter(function (te) {
                            return te.id !== selectedEvent.id;
                        });
                        _this3.set('events', tempEvents);
                        _this3.set('focusedPos', {
                            'latitude': selectedEvent.latitude,
                            'longitude': selectedEvent.longitude
                        });
                        _this3.set('markers', _this3.markers.filter(function (marker) {
                            if (marker.title === event.title) {
                                marker.setMap(null);
                                return false;
                            }
                            return true;
                        }));
                        _this3.set('editEvent', null);
                        _this3.growl.success('Event Deleted!');
                    })['catch'](function () {
                        _this3.growl.error('An error occurred deleting the event. Please try again.');
                    });
                }
            },
            sortNearestEvents: function sortNearestEvents(coords, events) {
                var latitude = coords.latitude;
                var longitude = coords.longitude;
                //180/pi
                var multiplier = Math.PI / 180;
                var latInRadians = latitude * multiplier;
                var longInRadians = longitude * multiplier;
                events.forEach(function (event) {
                    var bLatInRadians = event.latitude * multiplier;
                    var bLongInRadians = event.longitude * multiplier;
                    //Haversine Formula
                    var dlon = bLongInRadians - longInRadians;
                    var dlat = bLatInRadians - latInRadians;
                    var a = Math.pow(Math.sin(dlat / 2), 2) + Math.cos(latInRadians) * Math.pow(Math.sin(dlon / 2), 2);
                    var c = 2 * Math.asin(Math.sqrt(a));

                    //radius of earth in miles
                    var r = 3956;
                    var distance = Math.max(Math.round((c * r + Number.EPSILON) * 10) / 10, 0.1);
                    _ember['default'].set(event, 'distance', distance);
                });
                //sort asc by distance
                events.sort(function (a, b) {
                    return a.distance - b.distance;
                });
                return events;
            },
            resetEvent: function resetEvent(eventToDisplay) {
                this.actions.emptyEvent(eventToDisplay);
                this.actions.emptyEvent(this.newEvent);
            },
            resetMap: function resetMap() {
                var map = this.map;
                map.setCenter(new google.maps.LatLng(mapOptions.center.lat, mapOptions.center.lng));
                map.setZoom(mapOptions.zoom);
                this.actions.emptyEvent(this.eventToDisplay);
                this.actions.emptyEvent(this.newEvent);
            },
            searchAddress: function searchAddress(event) {
                var address = event.address;
                var input = document.getElementById('pac-input');
                input.value = event.title + ' ' + address.street + ' ' + address.city + ', ' + address.state + ' ' + address.zip_code;
                input.focus();
            },
            getTodaysDay: function getTodaysDay() {
                var days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
                return days[new Date().getDay()];
            },
            toggleShowAllHours: function toggleShowAllHours(event) {
                _ember['default'].set(event, 'showAllHours', !event.showAllHours);
            },
            scrollIntoElement: function scrollIntoElement(event) {
                var targetElement = document.getElementById('eventDetail' + event.id);
                // Check if the element is found
                if (targetElement) {
                    // Use the scrollIntoView method to scroll to the element
                    targetElement.scrollIntoView({ behavior: 'smooth', block: 'end' });
                }
            },
            returnToDashboard: function returnToDashboard() {
                this.transitionToRoute('frame.dashboard');
            },
            goToList: function goToList() {
                this.transitionToRoute('frame.service-map-list');
            }
        }
    });
});
/* global google */