define('impact-admin/controllers/frame/prepaid-codes/new', ['exports', 'ember', 'impact-admin/models/prepaid-code-groups', 'moment'], function (exports, _ember, _impactAdminModelsPrepaidCodeGroups, _moment) {
    function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

    var FEE = 0.008; // TODO: where is this value derived from?
    var MAX_APPLIED_FEE = 5;

    exports['default'] = _ember['default'].Controller.extend({

        multipleCards: [{
            number_of_cards: 0,
            amount_per_card: 0
        }],

        setDefaultDates: (function () {
            this.set('startDate', (0, _moment['default'])());
            this.set('endDate', (0, _moment['default'])("2025-2-14 01:00"));
            this.set('date_issued', (0, _moment['default'])());
        }).on('init'),

        setFilterFirstTime: (function () {
            this.set('filter', { endDate: "2025" });
        }).on('init'),

        assignPhysicalCodes: true,
        subtotal: 0,
        totalCards: 0,
        subtotalObserver: (function () {
            var subtotal = 0;
            var totalCards = 0;
            this.get('multipleCards').forEach(function (denomination) {
                subtotal += denomination.number_of_cards * denomination.amount_per_card;
                totalCards += parseInt(denomination.number_of_cards);
            });
            this.set('subtotal', subtotal);
            this.set('totalCards', totalCards);
        }).observes('multipleCards.@each.number_of_cards', 'multipleCards.@each.amount_per_card'),

        appliedFee: _ember['default'].computed('subtotal', function () {
            var appliedFee = this.get('subtotal') * FEE;
            return appliedFee > MAX_APPLIED_FEE ? MAX_APPLIED_FEE : appliedFee;
        }),
        totalValue: _ember['default'].computed('subtotal', 'appliedFee', function () {
            return this.get('subtotal') + this.get('appliedFee');
        }),
        amountNotNeeded: _ember['default'].computed('physical_cards', 'dmf_physical_cards', function () {
            return this.get('physical_cards') || this.get('dmf_physical_cards');
        }),
        physicalCardGroups: _ember['default'].computed('model', function () {
            var physicalCardGroups = this.get('model').physical_card_groups.content;
            var DMFphysicalCardGroups = this.get('model').dmf_physical_card_groups.content;
            return [{ id: 0, name: 'Choose a Group' }].concat(_toConsumableArray(physicalCardGroups), _toConsumableArray(DMFphysicalCardGroups));
        }),
        charities: _ember['default'].computed('model', function () {
            var charities = [];
            var organizations = this.get('model').organizations;
            organizations.forEach(function (charity) {
                charities.push(charity);
            });
            charities.sort(function (a, b) {
                var nameA = a.name.toUpperCase(); // ignore upper and lowercase
                var nameB = b.name.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }

                // names must be equal
                return 0;
            });
            charities.unshift({ id: 0, name: 'None' });
            return charities;
        }),

        actions: {

            markAsPaid: function markAsPaid() {
                this.set('payment_received', !this.get('payment_received'));
            },

            addDenomination: function addDenomination() {
                var multipleCards = this.get('multipleCards');
                multipleCards.push({
                    number_of_cards: 0,
                    amount_per_card: 0
                });
                console.log(multipleCards);

                this.set('multipleCards', multipleCards.sort().slice());
            },

            removeDenomination: function removeDenomination(index) {
                //If index is 0, do not delete as there must always be at least on card object
                if (index) {
                    var multipleCards = [].concat(_toConsumableArray(this.get('multipleCards')));
                    var removed = multipleCards.splice(index, 1);
                    console.log(removed);
                    this.set('multipleCards', multipleCards);
                }
            },

            setAssignPhysicalCards: function setAssignPhysicalCards(value) {
                if (!_ember['default'].isEmpty(value)) {
                    this.set('physical_cards', false);
                    this.set('dmf_physical_cards', false);
                    this.set('physical_card_group', null);
                }
                this.set('assignPhysicalCodes', value);
            },
            setPhysicalCards: function setPhysicalCards(value) {
                var multipleCards = this.get('multipleCards');
                this.set('multipleCards', [multipleCards[0]]);

                if (!_ember['default'].isEmpty(value)) {
                    this.set('assignPhysicalCodes', false);
                    this.set('dmf_physical_cards', false);
                    this.set('physical_card_group', null);
                }
                this.set('physical_cards', value);
            },
            setDMFPhysicalCards: function setDMFPhysicalCards(value) {
                var multipleCards = this.get('multipleCards');
                this.set('multipleCards', [multipleCards[0]]);

                if (!_ember['default'].isEmpty(value)) {
                    this.set('assignPhysicalCodes', false);
                    this.set('physical_cards', false);
                    this.set('physical_card_group', null);
                }
                this.set('dmf_physical_cards', value);
            },
            selectDeliveryMethod: function selectDeliveryMethod(value) {
                this.set('delivery_method', value);
            },
            selectTransactionType: function selectTransactionType(value) {
                this.set('transaction_type', value);
            },
            savePrepaidGroup: function savePrepaidGroup() {
                var _this = this;

                var totalCards = 0;
                if (this.get('multipleCards')) {
                    this.get('multipleCards').forEach(function (cardDenomination) {
                        totalCards += parseInt(cardDenomination.number_of_cards);
                    });

                    this.set('numCodes', totalCards);
                }

                if (!this.validate()) {
                    return;
                }

                var selectCodeGroup = this.get('physical_card_group');

                var selectCodeGroupMessage = selectCodeGroup && selectCodeGroup.id ? 'This action will pull ' + this.get('numCodes') + ' prepaid codes from the group ' + selectCodeGroup.name + '.' : 'This action will generate a list of ' + this.get('numCodes') + ' new codes.';

                if (!window.confirm(selectCodeGroupMessage)) {
                    return false;
                }

                var _getProperties = this.getProperties('name', 'description', 'startDate', 'endDate', 'numCodes', 'codeValue', 'email', 'accessCode', 'physical_cards', 'dmf_physical_cards', 'purchaser_name', 'billing_street', 'billing_city', 'billing_state', 'billing_postal_code', 'business', 'purchaser_email', 'phone', 'date_issued', 'issued_by', 'transaction_type', 'physical_card_group', 'overage_org', 'delivery_method', 'payment_received');

                var name = _getProperties.name;
                var description = _getProperties.description;
                var startDate = _getProperties.startDate;
                var endDate = _getProperties.endDate;
                var numCodes = _getProperties.numCodes;
                var codeValue = _getProperties.codeValue;
                var email = _getProperties.email;
                var accessCode = _getProperties.accessCode;
                var physical_cards = _getProperties.physical_cards;
                var dmf_physical_cards = _getProperties.dmf_physical_cards;
                var purchaser_name = _getProperties.purchaser_name;
                var billing_street = _getProperties.billing_street;
                var billing_city = _getProperties.billing_city;
                var billing_state = _getProperties.billing_state;
                var billing_postal_code = _getProperties.billing_postal_code;
                var business = _getProperties.business;
                var purchaser_email = _getProperties.purchaser_email;
                var phone = _getProperties.phone;
                var date_issued = _getProperties.date_issued;
                var issued_by = _getProperties.issued_by;
                var transaction_type = _getProperties.transaction_type;
                var physical_card_group = _getProperties.physical_card_group;
                var overage_org = _getProperties.overage_org;
                var delivery_method = _getProperties.delivery_method;
                var payment_received = _getProperties.payment_received;

                var newPrepaidCodeGroup = _impactAdminModelsPrepaidCodeGroups['default'].createRecord({
                    name: name,
                    description: description,
                    start_date: startDate.toJSON(),
                    end_date: endDate.toJSON(),
                    num_codes: numCodes,
                    prepaid_value_per_code: codeValue || "0",
                    prepaid_multiple_denominations: this.get('multipleCards') || [],
                    email: email,
                    access_code: accessCode,
                    physical_cards: physical_cards,
                    dmf_physical_cards: dmf_physical_cards,
                    dmf_code: physical_card_group ? physical_card_group.dmf_physical_card_groups : false,
                    purchaser_name: purchaser_name,
                    billing_street: billing_street,
                    billing_city: billing_city,
                    billing_state: billing_state,
                    billing_postal_code: billing_postal_code,
                    business: business,
                    purchaser_email: purchaser_email,
                    phone: phone,
                    date_issued: date_issued ? date_issued.toJSON() : null,
                    issued_by: issued_by,
                    transaction_type: transaction_type,
                    physical_card_group_id: physical_card_group ? physical_card_group.id : null,
                    overage_org_id: overage_org ? overage_org.id : null,
                    delivery_method: delivery_method,
                    payment_received: payment_received
                });
                newPrepaidCodeGroup.save().then(function () {
                    _this.reset();
                    _this.transitionToRoute('frame.prepaid-codes');
                })['catch'](function (error) {
                    _this.growl.error(error);
                    console.log(error);
                });
            }
        },

        validate: function validate() {
            var isNullOrUndefinedOrEmpty = function isNullOrUndefinedOrEmpty(obj) {
                return obj === undefined || obj === null || obj === '';
            };

            this.set('errors', _ember['default'].A());
            var errors = this.get('errors');

            if (isNullOrUndefinedOrEmpty(this.get('name'))) {
                errors.pushObject('Please enter a prepaid code group name.');
            }
            if (isNullOrUndefinedOrEmpty(this.get('description'))) {
                errors.pushObject('Please enter a description.');
            }

            if (isNullOrUndefinedOrEmpty(this.get('startDate')) || isNullOrUndefinedOrEmpty(this.get('endDate'))) {
                errors.pushObject('Please enter a start and end date.');
            } else if (this.get('startDate') > this.get('endDate')) {
                errors.pushObject('End date must be after the start date.');
            }

            if (this.get('numCodes') < 1) {
                errors.pushObject('You must generate at least 1 code.');
            }

            if (isNullOrUndefinedOrEmpty(this.get('physical_cards')) && isNullOrUndefinedOrEmpty(this.get('dmf_physical_cards')) && this.get('codeValue') < 10) {
                errors.pushObject('Each code must be worth at least $10.');
            }

            if (this.get('assignPhysicalCodes')) {
                if (isNullOrUndefinedOrEmpty(this.get('physical_card_group'))) {
                    errors.pushObject('Physical card group must be assigned.');
                }

                if (isNullOrUndefinedOrEmpty(this.get('purchaser_name')) || isNullOrUndefinedOrEmpty(this.get('date_issued')) || isNullOrUndefinedOrEmpty(this.get('issued_by')) || isNullOrUndefinedOrEmpty(this.get('overage_org')) || isNullOrUndefinedOrEmpty(this.get('delivery_method')) || isNullOrUndefinedOrEmpty(this.get('transaction_type'))) {
                    errors.pushObject('Please complete all fields for manual purchases');
                }
            }

            return errors.length === 0;
        },
        reset: function reset() {
            this.set('name', null);
            this.set('description', null);
            this.set('numCodes', null);
            this.set('codeValue', null);
            this.set('email', null);
            this.set('accessCode', null);
            this.set('physical_cards', false);
            this.set('dmf_physical_cards', false);
            this.set('assignPhysicalCodes', false);
            this.set('multipleCards', [{
                number_of_cards: 0,
                amount_per_card: 0
            }]);
            this.set('startDate', (0, _moment['default'])());
            this.set('endDate', (0, _moment['default'])("2025-2-14"));

            this.set('date_issued', (0, _moment['default'])());
        }
    });
});